import React, {useState} from "react"
import {Prestavi} from "prestavi-website-react";
import Footer from "../components/Footer/Footer";

const styles = require("../styles/pages/Contact.module.scss");

export default function SubmitIssue() {
    return (
        <div className={styles.Contact}>
            <div className={styles.PageTitle}>
                <h1>Submit a Bug Report</h1>
                <p>Please provide the following details about the issue you've discovered.</p>
            </div>

            <Prestavi
                id="bvqqq4f8koujoahs00073h643db9k8iy"
                border="1px solid #caced9"
            />

            <div style={{marginTop:"36px"}}></div>
            <Footer />
        </div>
    )
}

